import React, { useState, useEffect } from 'react';
import './App.css';
import logo from './kraken_logo.png'; // Импортируйте ваш логотип

const PERMITTED_FORMATS = [
  'mp3', 'ogg', 'wav', 'aiff', 'ape', 'flac', 'wma', 'opus', 'aa', 'aac',
  'ac3', 'adx', 'ahx', 'asf', 'au', 'aud', 'dmf', 'dts', 'dxd', 'mmf', 'mod',
  'mp1', 'mp2', 'mp4', 'mpc', 'ra', 'tta', 'voc', 'vox', 'vqf', 'xm', 'cd',
  'dvd-audio', 'mqa', 'cdda', 'dsd', 'dxd', 'm4a', 'zip', 'rar', 'tar', 'arg',
  'cab', 'gz', 'webp', 'mp4', 'avi', 'mpg4', 'm4v', 'mpg', 'mpeg', 'mpe', 'ts',
  'mov', 'qt', 'mov', 'mkv', 'jpeg', 'jpg', 'png', 'bmp', 'tif', 'xml', 'pdf',
  'doc', 'docx', 'xls', 'epub', 'fb2', 'txt', 'odt', 'rtf'
];

function App() {
  useEffect(() => {
    if (window.Telegram) {
      window.Telegram.WebApp.ready();
    }

    const interval = setInterval(() => {
      updateRemainingTime();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const [text, setText] = useState('');
  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [remainingTime, setRemainingTime] = useState(null);

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const fileExtension = selectedFile.name.split('.').pop().toLowerCase();

    if (!PERMITTED_FORMATS.includes(fileExtension)) {
      setError(`Формат файла .${fileExtension} не поддерживается.`);
      return;
    }

    setFiles([...files, selectedFile]);
    setFileNames([...fileNames, shortenFileName(selectedFile.name)]);
    setError('');
  };

  const handleFileRemove = (index) => {
    const newFiles = [...files];
    const newFileNames = [...fileNames];
    newFiles.splice(index, 1);
    newFileNames.splice(index, 1);
    setFiles(newFiles);
    setFileNames(newFileNames);
  };

  const handleSubmit = () => {
    const lastSubmitTime = localStorage.getItem('lastSubmitTime');
    const currentTime = Date.now();

    if (lastSubmitTime && currentTime - lastSubmitTime < 3600000) { // 3600000 ms = 1 hour
      setError('Вы можете отправлять данные только раз в час.');
      updateRemainingTime();
      return;
    }

    if (!text) {
      setError('Пожалуйста, введите текст.');
      return;
    }

    setLoading(true);
    setMessage('');
    setError('');
    setProgress(0);

    const formData = new FormData();
    formData.append('text', text);
    files.forEach((file, index) => {
      formData.append('file', file);
    });
    
    // Добавляем данные для аутентификации от Telegram Web App
    formData.append('tg_initData', window.Telegram.WebApp.initData);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://kraken.ajncloud.shop/share/share-user-date/', true);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        setProgress(percentComplete);
      }
    };

    xhr.onload = () => {
      setLoading(false);
      if (xhr.status === 200 || xhr.status === 201) {
        setMessage('Данные успешно отправлены!');
        localStorage.setItem('lastSubmitTime', Date.now()); // Сохраняем текущее время как время последней отправки
        // Перезагрузка страницы после успешной отправки
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setMessage(`Ошибка: ${xhr.statusText} (${xhr.status})`);
      }
    };

    xhr.onerror = () => {
      setLoading(false);
      setMessage('Произошла ошибка при отправке данных.');
    };

    xhr.send(formData);
  };

  const shortenFileName = (name) => {
    if (name.length <= 10) return name;
    const ext = name.split('.').pop();
    return `${name.slice(0, 5)}...${name.slice(-5)}`;
  };

  const updateRemainingTime = () => {
    const lastSubmitTime = localStorage.getItem('lastSubmitTime');
    if (lastSubmitTime) {
      const currentTime = Date.now();
      const timeDiff = 3600000 - (currentTime - lastSubmitTime); // 3600000 ms = 1 hour
      if (timeDiff > 0) {
        const minutes = Math.floor(timeDiff / 60000);
        const seconds = Math.floor((timeDiff % 60000) / 1000);
        setRemainingTime(`${minutes}м ${seconds}с`);
      } else {
        setRemainingTime(null);
      }
    } else {
      setRemainingTime(null);
    }
  };

  const isSubmitDisabled = () => {
    return loading || remainingTime !== null;
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" /> {/* Добавляем логотип */}
        <div className="form">
          <textarea 
            value={text} 
            onChange={handleTextChange} 
            placeholder="Введите текст"
            className="textarea"
          />
          {files.map((file, index) => (
            <div key={index} className="file-name-container">
              <p className="file-name">{fileNames[index]}</p>
              <button onClick={() => handleFileRemove(index)} className="remove-button">Удалить файл</button>
            </div>
          ))}
          {files.length < 10 && (
            <div className="file-input-container">
              <label htmlFor={`fileInput-${files.length}`} className="file-input-label">Выберите файл</label>
              <input 
                type="file" 
                onChange={handleFileChange} 
                className="file-input" 
                id={`fileInput-${files.length}`}
              />
            </div>
          )}
          <button onClick={handleSubmit} className="submit-button" disabled={isSubmitDisabled()}>
            {loading ? 'Отправка...' : 'Отправить'}
          </button>
          {loading && <progress value={progress} max="100" className="progress-bar">{progress}%</progress>}
          {message && <p className="message">{message}</p>}
          {error && <p className="error">{error}</p>}
          {remainingTime && <p className="remaining-time">Вы сможете отправить данные через: {remainingTime}</p>}
          <p className="privacy-policy">Мы не храним ваши личные данные</p>
        </div>
      </header>
    </div>
  );
}

export default App;
